import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';
import classnames from 'classnames';
import {navigate} from '@reach/router';

const Case12 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/12.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      const linkColor = classnames(classes.textColor, classes.link);

      const clickButton = () => {
        navigate('#simple-articles-consumer');
      };

      return (
        <div className={classes.case} id="order-app">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>Use Case #12. Order app</h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            You want to get online orders from your customers. Orders can be
            placed either from mobile app or from a e-commerce web-site. A
            custom made mobile app can be provided under white-label.
            srv4pos.com is able to accept VISA/mastercard payments using
{" "}
            <a href="https://www.dibspayment.com/">
              <span className={classes.textColor}>DIBS payment system</span>
            </a>
            . Orders can be accessed via srv4pos.com own back-office or
            delivered directly to your management system.
          </p>
          <Img fluid={img} style={{maxWidth: 803}} />
          <p style={{marginTop: 60, marginBottom: 20}}>
            Your management system can get orders by calling{" "}
            {'GET /orders-diff/{versionFrom}/{versionTo} (listDiff())'}.
          </p>
          <p style={{marginBottom: 20}}>
            Your order app can send order via POST /orders (create()) .
          </p>
          <p style={{marginBottom: 20}}>
            Here is full-featured sample both sending and getting orders.
          </p>
          <p style={{marginBottom: 20}}>
            Order app can get articles as described in{" "}
            <span className={linkColor} onClick={clickButton}>
              Use Case #1. Simple Articles consumer
            </span>
            .
          </p>
          <p>
            In addition to that, it’s possible to book a service (e.g. a table
            in a restaurant or timeslot in heardressing salon) over POST
            /bookings (create()) .
          </p>
        </div>
      );
    }}
  />
);

Case12.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case12);
